import {Link, graphql} from 'gatsby'
import Container from '../components/container'
import Fade from 'react-reveal/Fade'
import Layout from '../components/layout'
import React, {useState} from 'react'
import styled from 'styled-components'
import {PAGE_ROOT_ELEMENT_ID} from '../constants/global-constants'

export default props => {
  const {data} = props

  const prismicData = {
    postEnquiryText: data.prismicEDesignPage.data.post_enquiry_text.html,
  }

  return (
    <Layout documentTitlePrefix="Thanks" isIndexPage={false} pageName="thanks" showScrollToTopBtn={false}>
      <Container>
        <Fade>
          <StyledThanksMessage>
            <div className="thanks-text" dangerouslySetInnerHTML={{__html: prismicData.postEnquiryText}} />
            <p className="edesign-link-wrapper">
              <Link to="/e-design">Back to E-Design page</Link>
            </p>
          </StyledThanksMessage>
        </Fade>
      </Container>
    </Layout>
  )
}

const StyledThanksMessage = styled.div`
  font-weight: 100;
  margin-top: 10rem;
  text-align: center;

  .thanks-text {
    font-size: 1.8rem;
    margin-bottom: 2rem;

    p {
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .edesign-link-wrapper {
    font-size: 1.4rem;

    a {
      text-decoration: underline;
    }
  }
`

export const pageQuery = graphql`
  {
    prismicEDesignPage {
      data {
        post_enquiry_text {
          html
        }
      }
    }
  }
`
